<template>
  <div class="container text-center">
    <h3 class="text-center" style="margin-top: 5%; margin-bottom: 5%">
      รายงาน
    </h3>
    <div class="row">
      <div class="col">
        <div class="card text-white col-sm" style="background-color: #6392ec">
          <div class="card-body">
            <h5 class="text-start card-title">รายงานก่อนปี 2559</h5>
            <div class="text-start">
              <i
                class="bi bi-arrow-right-circle-fill"
                style="color: #ffffff"
                @click="expand1 = !expand1"
              ></i>
            </div>
          </div>
        </div>
        <div v-if="expand1" class="mt-5">
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_select2.php"
              style="color: #3eb1b1"
              >รายงานสตรีถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_select_total.php"
              style="color: #3eb1b1"
              >รายงานเด็กและสตรีที่ถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_summary_1.php"
              style="color: #3eb1b1"
              >สรุปรายงานการให้บริการเด็กของศูนย์พึ่งได้</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_summary_hospital.php"
              style="color: #3eb1b1"
              >สรุปรายงานการให้บริการของศูนย์พึ่งได้</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_summary_dual_1.php"
              style="color: #3eb1b1"
              >สรุปรายงานการให้บริการเด็กของศูนย์พึ่งได้
              ตามเหตุการณ์ถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_summary_dual_2.php"
              style="color: #3eb1b1"
              >สรุปรายงานการให้บริการผู้หญิงของศูนย์พึ่งได้
              ตามเหตุการณ์ถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_area1.php?t=1"
              style="color: #3eb1b1"
              >แผนที่สัดส่วนจำนวนเด็กที่ถูกกระทำรุนแรงเทียบกับจำนวนประชากร</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_area2.php?t=2"
              style="color: #3eb1b1"
              >แผนที่สัดส่วนจำนวนสตรีที่ถูกกระทำรุนแรงเทียบกับจำนวนประชากร</a
            >
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card text-white col-sm" style="background-color: #747af2">
          <div class="card-body">
            <h5 class="text-start card-title">รายงานตั้งแต่ปี 2559 -2564</h5>
            <div class="text-start">
              <i
                class="bi bi-arrow-right-circle-fill"
                style="color: #ffffff"
                @click="expand2 = !expand2"
              ></i>
            </div>
          </div>
        </div>
        <div v-if="expand2" class="mt-5">
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_abuse_select.php"
              style="color: #3eb1b1"
              >รายงานข้อมูลผู้ที่ถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_preg_select.php"
              style="color: #3eb1b1"
              >รายงานข้อมูลผู้ที่ตั้งครรภ์ไม่พึ่งประสงค์</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HE"
              style="color: #3eb1b1"
              >สรุปรายงานข้อมูลผู้ที่ถูกกระทำรุนแรง</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_pregnancy.php"
              style="color: #3eb1b1"
              >สรุปรายงานข้อมูลผู้ที่ตั้งครรภ์ไม่พึ่งประสงค์</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_help_abuse_pregnancy.php"
              style="color: #3eb1b1"
              >สรุปรายงานการให้บริการผู้ที่ถูกกระทำรุนแรงร่วมกับการตั้งครรภ์ไม่พึ่งประสงค์</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_summary_hospital_new.php"
              style="color: #3eb1b1"
              >สรุปรายงานภาพรวม</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_admin_user.php"
              style="color: #3eb1b1"
              >รายงานผู้รับผิดชอบ</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_area4.php?t=3"
              style="color: #3eb1b1"
              >แผนที่สัดส่วนจำนวนผู้ที่ถูกกระทำรุนแรงเทียบกับจำนวนประชากร</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_area5.php?t=4"
              style="color: #3eb1b1"
              >แผนที่สัดส่วนจำนวนผู้ที่ตั้งครรภ์ไม่พึงประสงค์เทียบกับจำนวนประชากร</a
            >
          </div>
          <div
            class="card border-success text-start mb-5"
            style="padding: 20px; color: #3eb1b1"
          >
            <a
              href="http://phdbreport.moph.go.th/hssd1/umd/HSS_HELP/report/report_area3.php"
              style="color: #3eb1b1"
              >แผนที่สัดส่วนจำนวนผู้ที่ถูกกระทำความรุนแรงและผู้ที่ที่ตั้งครรภ์ไม่พึงประสงค์เทียบกับจำนวนประชากร</a
            >
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card text-white col-sm" style="background-color: #3eb1b1">
          <div class="card-body">
            <h5 class="text-start card-title">
              รายงานตั้งแต่ปี 2565
            </h5>
            <div class="text-start">
              <i
                class="bi bi-arrow-right-circle-fill"
                style="color: #ffffff"
                @click="expand3 = !expand3"
              ></i>
            </div>
          </div>
        </div>
        <div v-if="expand3 && user.role === 1" class="mt-5">
          <div
            v-for="(item, i) in adminReportList"
            @click="goto(item.path)"
            :key="i"
            class="card border-success text-start mb-5 pointer"
            style="padding: 20px; color: #3eb1b1"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="expand3 && user.role === 2" class="mt-5">
          <div
            v-for="(item, i) in sjrReportList"
            @click="goto(item.path)"
            :key="i"
            class="card border-success text-start mb-5 pointer"
            style="padding: 20px; color: #3eb1b1"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="expand3 && user.role === 3" class="mt-5">
          <div
            v-for="(item, i) in staffReportList"
            @click="goto(item.path)"
            :key="i"
            class="card border-success text-start mb-5 pointer"
            style="padding: 20px; color: #3eb1b1"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Report",
  computed: {
    ...mapState({
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  data() {
    return {
      expand1: false,
      expand2: false,
      expand3: false,
      adminReportList: [
        // { name: "รายงานเลือกตัวแปรของข้อมูล", path: "report-dynamic" },
        {
          name: "รายงานสรุปจำนวนข้อมูลที่บันทึกเข้ามาในแต่ละข้อคำถามของแบบบันทึก",
          path: "report-summary-question",
        },
        {
          name: "สรุปรายงานภาพรวม ของจำนวนการกรอกข้อมูล",
          path: "report-summary-recorder",
        },
        { name: "รายงานผู้รับผิดชอบ", path: "reportresponsible" },
        // {
        //   name: "รายงานแผนที่สัดส่วนแสดงเป็นจำนวนผู้ถูก กระทำเทียบกับจำนวนประชากรแต่ละพื้นที่",
        //   path: "report-summary-map",
        // },
      ],
      staffReportList: [
        { name: "รายงานเลือกตัวแปรของข้อมูล", path: "report-dynamic" },
        {
          name: "รายงานสรุปจำนวนข้อมูลที่บันทึกเข้ามาในแต่ละข้อคำถามของแบบบันทึก",
          path: "report-summary-question",
        },
        {
          name: "สรุปรายงานภาพรวม ของจำนวนการกรอกข้อมูล",
          path: "report-summary-recorder",
        },
        // {
        //   name: "รายงานแผนที่สัดส่วนแสดงเป็นจำนวนผู้ถูก กระทำเทียบกับจำนวนประชากรแต่ละพื้นที่",
        //   path: "report-summary-map",
        // },
      ],
      sjrReportList: [
        // { name: "รายงานเลือกตัวแปรของข้อมูล", path: "report-dynamic" },
        {
          name: "รายงานสรุปจำนวนข้อมูลที่บันทึกเข้ามาในแต่ละข้อคำถามของแบบบันทึก",
          path: "report-summary-question",
        },
        {
          name: "สรุปรายงานภาพรวม ของจำนวนการกรอกข้อมูล",
          path: "report-summary-recorder",
        },
        // {
        //   name: "รายงานแผนที่สัดส่วนแสดงเป็นจำนวนผู้ถูก กระทำเทียบกับจำนวนประชากรแต่ละพื้นที่",
        //   path: "report-summary-map",
        // },
      ],
    };
  },
  methods: {
    goto(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
card {
  padding: 10px 10px 10px 10px;
}
</style>
